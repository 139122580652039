import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';

// Layout component
import Layout from '../components/layouts/Layout';
import HeaderMap from '../components/HeaderMap';
import BannerPicture from '../components/BannerPicture';
import CustomersLogos from '../components/CustomersLogos';
import TwoColumnsIcons from '../components/TwoColumnsIcons';
import BackgroundPicture from '../components/BackgroundPicture';
import SectionOnlyFooter from '../components/SectionOnlyFooter';
import ContentReverse from '../components/ContentReverse';

const TwoColumnsLayout = styled.div`
    margin: 7% 0%;
`;

const BgDarkBlue = styled.div`
    position:absolute;
    left:0;
    z-index: -10000;
    background-color: #0c499b;
    width: 100%;
    height: 36%;

    @media (max-width: 768px) {
        height:22%;
    }
`;

const LivemapIframe = styled.div`
    display: flex;
    width: 100%;
    height: 560px;
    border-radius: 10px;
    overflow: hidden;
    border: none;
    box-shadow: 3px 3px 18px 3px rgba(99,99,99,.16863);
`;


const IndexPage = ({ data }) => {
    const { t } = useTranslation('eventsPage');


    const icons = [
        {
            icon: 'icon-event_note',
            text: t('TEXT_SIMPLE_UX')
        },
        {
            icon: 'icon-address',
            text: t('TEXT_MULTI_FLOOR')
        },
        {
            icon: 'icon-camera',
            text: t('TEXT_HIGHLIGHTED_CONTENT')
        },
        {
            icon: 'icon-embed2',
            text: t('TEXT_ERGONOMIC')
        }];

    const right = [
        {
            icon: 'icon-camera',
            text: t('TEXT_VPS')
        },
        {
            icon: 'icon-location-current',
            text: t('TEXT_STEP_BY_STEP')
        },
        {
            icon: 'icon-map-marker',
            text: t('TEXT_ITINERARIES')
        },
        {
            icon: 'icon-spinner',
            text: t('TEXT_ONLINE')
        }];


    return (
        <React.Fragment>
            <Helmet defer={false}>
                <title>{t('META_TITLE_EVENT')}</title>
                <meta name="description"
                    content={t('META_DESCRIPTION_EVENT')}/>
            </Helmet>
            <Layout>
                <BackgroundPicture
                    image={data.background.childImageSharp}
                    alt='mobile with map in citysquare'
                />
                <div className='wemap-page-index'>
                    <h1 className='wemap-page-index-title'
                        dangerouslySetInnerHTML={{ __html: t('TITLE') }}></h1>

                    <CustomersLogos
                        title={t('TITLE_POWER_EVENTS')}
                        size='small'
                        customersLogos={[
                            {image: '/images/industries/events/vivatech.png'},
                            {image: '/images/industries/events/feno-logo.png'},
                            {image: '/images/industries/events/fff.png'},
                            {image: '/images/industries/events/vdl-logo-v2.png'},
                            {image: '/images/industries/events/ebg-logo.png'},
                            {image: '/images/industries/events/fete_de_humanite.png'},
                            {image: '/images/industries/events/accor-arena-logo.png'}
                        ]}
                    />

                    <SectionOnlyFooter
                        titleSection={t('TITLE_RESCUE_EVENTS')}
                        cellParagraph={t('TITLE_SHARED_MAP')}
                        pictureRight={data.vivatech.childImageSharp}
                        pictureRightWidth="40%"
                        altDescription='mobile with open Wemap pinpoint'
                        titleButton={t('TITLE_BUTTON_TRY_OUT_NOW')}
                        urlFooter='/contact/'
                        iconsOnlyFooterComponent={[
                            {
                                icon: 'icon-plane',
                                text: t('TEXT_CONFERENCE')
                            },
                            {
                                icon: 'icon-road',
                                text: t('TEXT_EXHIBITIONS')
                            },
                            {
                                icon: 'icon-location22',
                                text: t('TEXT_CONGRESS')
                            },
                            {
                                icon: 'icon-map22',
                                text: t('TEXT_CONCERT')
                            }
                        ]}
                    />

                    <TwoColumnsLayout>
                        <TwoColumnsIcons title={t('TITLE_BEST_EXPERIENCE')}
                            text={t('TITLE_CREATE_MAP')}
                            titleButton={t('BUTTON_LEARN_MORE')}
                            icons={icons}
                            leftIcon={data.ebg.childImageSharp}
                            altColumnLogo='logo of EBG'
                            urlFooter='/indoor-maps/'
                        />
                    </TwoColumnsLayout>

                    <SectionOnlyFooter
                        titleSection={t('TITLE_HELP_VISITORS')}
                        cellParagraph={t('TITLE_FIND_SEAT')}
                        pictureRight={data.mobileMockup.childImageSharp}
                        videoUrl='/images/industries/events/feno-itineraire.mp4'
                        pictureRightWidth="40%"
                        altDescription='mobile with open Wemap pinpoint'
                        titleButton={t('TITLE_BUTTON_TALK_PROJECT')}
                        urlFooter='/contact/'
                        iconsOnlyFooterComponent={[
                            {
                                icon: 'icon-layers1',
                                text: t('TEXT_MULTI_ROUTE')
                            },
                            {
                                icon: 'icon-road',
                                text: t('TEXT_ACCESSIBLE_ROUTE')
                            },
                            {
                                icon: 'icon-my_location',
                                text: t('TEXT_TRANSITION')
                            },
                            {
                                icon: 'icon-map22',
                                text: t('TEXT_MULTIMODAL')
                            }
                        ]}
                    />

                    <SectionOnlyFooter
                        titleSection={t('TITLE_BRANDED_SERVICE')}
                        cellParagraph={t('TITLE_LIVE_CONTENT')}
                        pictureRight={data.accorArena.childImageSharp}
                        videoUrl4='/images/industries/events/accor-arena-carte.mp4'
                        pictureRightWidth="40%"
                        altDescription='mobile with open Wemap pinpoint'
                        titleButton={t('TITLE_FREE_DEMO')}
                        urlFooter='/contact/'
                        iconsOnlyFooterComponent={[
                            {
                                icon: 'icon-plane',
                                text: t('TEXT_BRAND_GUIDLINE')
                            },
                            {
                                icon: 'icon-phone',
                                text: t('TEXT_MOBILE')
                            },
                            {
                                icon: 'icon-embed2',
                                text: t('TEXT_SDK_INTERGRATION')
                            },
                            {
                                icon: 'icon-pencil',
                                text: t('TEXT_TOOLS')
                            },
                            {
                                icon: 'icon-event_note',
                                text: t('TEXT_DEPLOY')
                            }
                        ]}
                    />

                    <SectionOnlyFooter
                        titleSection={t('TITLE_POSITIONNING')}
                        cellParagraph={t('TITLE_WEMAP_INDOOR')}
                        pictureRight={data.mobileMockup.childImageSharp}
                        videoUrl='/images/industries/events/salon-ses.mp4'
                        pictureRightWidth="40%"
                        altDescription='mobile with open Wemap pinpoint'
                        titleButton={t('TITLE_CONTACT_US')}
                        urlFooter='/contact/'
                        iconsOnlyFooterComponent={[
                            {
                                icon: 'icon-plugin',
                                text: t('TEXT_FUSION_TECH')
                            },
                            {
                                icon: 'icon-my_location',
                                text: t('TEXT_OUTDOOR_GPS')
                            },
                            {
                                icon: 'icon-location22',
                                text: t('TEXT_PROPRIETARY')
                            },
                            {
                                icon: 'icon-check-circle',
                                text: t('TEXT_NO_INFRA')
                            }
                        ]}
                    />

                    <ContentReverse
                        title={t('TITLE_SUPERCHARGED')}
                        leftIcon={data.vps.childImageSharp}
                        altDescription='mobile with pinpoints and a circuit on map'
                        titleButton={t('TITLE_BUTTON_LEARN_MORE')}
                        rightIcons={right}
                        urlFooter='/augmented-reality/'
                    />

                    <BgDarkBlue></BgDarkBlue>
                    <HeaderMap customerCicleImageUrl='/images/industries/events/logo_circle_vdl.png'
                        alt='Le Salon des Véhicules de Loisirs logo'
                        titleCaseStudy={t('Case Study')}
                        title={t('TITLE_VDL')}
                        by={t('TEXT_BY')}
                        text={t('TITLE_DESCRIPTION_VDL')}
                        textPointInfos={t('TITLE_POINTS_VDL')}
                        textViewsInfos={t('TITLE_VIEWS_VDL')}
                        descriptionCustomer={t('TITLE_VDL_DESCRIPTION_MAP')}
                        infosRight1={t('TITLE_DESCRIPTION_RIGHT_VDL_0')}
                        infosRight2={t('TITLE_DESCRIPTION_RIGHT_VDL_1')}
                        infosRight3={t('TITLE_DESCRIPTION_RIGHT_VDL_2')}
                    />

                    <LivemapIframe>
                        <iframe title="livemap"
                            width="100%"
                            height="100%"
                            src="https://livemap.getwemap.com/dom.html?emmid=20740&token=UXC6WAPU2YRY8PEPUTM97WIAT"
                            allowfullscreen
                            frameborder="0"></iframe>
                    </LivemapIframe>

                    <SectionOnlyFooter
                        titleSection={t('TITLE_CONTENT_LEVERAGE')}
                        pictureRight={data.webApps.childImageSharp}
                        pictureRightWidth="48%"
                        altDescription='mobile with connexion screen to media'
                        titleButton={t('TITLE_BUTTON_DISCOVER_WEMAP')}
                        urlFooter='/products/'
                        iconsOnlyFooterComponent={[
                            {
                                icon: 'icon-map2',
                                text: t('DESCRIPTION_CONTENT_EASE')
                            },
                            {
                                icon: 'icon-checkmark',
                                text: t('DESCRIPTION_CREATE_DEPLOY')
                            },
                            {
                                icon: 'icon-user-plus',
                                text: t('DESCRIPTION_ENRICH_MAP')
                            },
                            {
                                icon: 'icon-coin-dollar',
                                text: t('DESCRIPTION_MONETIZE')
                            }
                        ]}
                    />

                    <BannerPicture backgroundBanner={data.banner.childImageSharp}
                        alt='Footer banner with monument'
                        titleBannerPicture={t('TITLE_BANNER_CASE_STUDIES')}
                        titleButton={t('TITLE_BUTTON_TRY_OUT_NOW')}
                        buttonUrl='/contact/'
                    />

                </div>
            </Layout>
        </React.Fragment>
    );
};

IndexPage.propTypes = {data: PropTypes.object};

export default IndexPage;

export const query = graphql`{
  background: file(relativePath: {eq: "background-image/events-bg.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  vivatech: file(relativePath: {eq: "industries/events/vivatech-mobile.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, width: 490, layout: FIXED)
    }
  }
  events: file(relativePath: {eq: "industries/events/vivatech-mobile.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, width: 330, layout: FIXED)
    }
  }
  mobileMockup: file(
    relativePath: {eq: "products/wemap-navigation/white_mobile_augmented-reality.png"}
  ) {
    childImageSharp {
      gatsbyImageData(quality: 75, width: 320, layout: FIXED)
      fluid(quality: 75) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
  accorArena: file(relativePath: {eq: "industries/events/kiosk-accor-arena.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, width: 290, layout: FIXED)
    }
  }
  vps: file(relativePath: {eq: "industries/events/iphone-vps-v3.png"}) {
    childImageSharp {
        gatsbyImageData(quality: 75, layout: FULL_WIDTH)
      }
  }
  ebg: file(relativePath: {eq: "industries/events/ebg-logo.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, width: 100, layout: CONSTRAINED)
    }
  }
  fds: file(relativePath: {eq: "industries/events/card-horizontal-fds-2019.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, layout: FULL_WIDTH)
    }
  }
  icom: file(relativePath: {eq: "industries/events/card-horizontal-icom.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, layout: FULL_WIDTH)
    }
  }
  napoleon: file(
    relativePath: {eq: "industries/events/card-horizontal-napoleon.png"}
  ) {
    childImageSharp {
      gatsbyImageData(quality: 75, layout: FULL_WIDTH)
    }
  }
  jep: file(relativePath: {eq: "industries/events/card-horizontal-jep.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, layout: FULL_WIDTH)
    }
  }
  cnl: file(relativePath: {eq: "industries/events/card-horizontal-cnl.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, layout: FULL_WIDTH)
    }
  }
  ndm: file(relativePath: {eq: "industries/events/card-horizontal-ndm-2019.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, layout: FULL_WIDTH)
    }
  }
  rdvj: file(
    relativePath: {eq: "industries/events/card-horizontal-rdvj-2019.png"}
  ) {
    childImageSharp {
      gatsbyImageData(quality: 75, layout: FULL_WIDTH)
    }
  }
  fes: file(relativePath: {eq: "industries/events/fete-en-seine-2019.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, layout: FULL_WIDTH)
    }
  }
  rs: file(relativePath: {eq: "industries/events/card-horizontal-rs.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, layout: FULL_WIDTH)
    }
  }
  webApps: file(relativePath: {eq: "products/wemap-web/wemap_web_apps.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, width: 420, layout: FIXED)
    }
  }
  banner: file(relativePath: {eq: "industries/events/wemap_picture_events.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, layout: FULL_WIDTH)
    }
  }
}
`;
